.pre-answer {
  margin-right: 3px;
}
.question-select {
  margin-top: 5px !important;
}
.select-option {
  margin: auto !important;
  text-align: left;
  // page-break-inside: avoid;
  // page-break-before: always;
}
@media print {
  .question-select {
    // display: inline-flex !important;
  }
  .select-option {
    max-width: auto !important;
  }
}
