.gdoc-image {
  max-width: 100%;
  height: auto;

  object-position: center;
  margin: auto;
  display: block;
}
.image-inline {
  display: inline-block;
}
.image-block {
  display: block;
  margin: 3px 0;
}
