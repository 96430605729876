.table-custom {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  // margin-bottom: 1rem;
  width: 100%;
  border-color: #dee2e6;
  text-align: center;
  vertical-align: middle;
}
.table-render {
  padding: 3px 5px;
  margin: auto;
}
$px: px;
@for $i from 1px through 1900px {
  .table-render-#{$i} {
    width: $i;
  }
  @media screen and (max-width: $i) {
    .table-render-#{$i} {
      width: 100% !important;
    }
  }
}
@for $i from 1 through 100 {
  .col-render-#{$i} {
    width: $i + '%';
  }
}
@media screen and (min-width: 1920px) {
  .table-render {
    width: 100% !important;
  }
}
